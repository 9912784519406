<!--
 * @Description: 重置密码
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/views/user/ResetPassword.vue
-->
<template>
  <div class="">
    <header class="flex-between">
      <span>智慧云平台</span>
      <span @click="$router.back()">返回登陆</span>
    </header>
    <el-steps :active="active" align-center>
      <el-step title="填写账号"></el-step>
      <el-step title="身份验证"></el-step>
      <el-step title="设置新密码"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <div class="main-content">
      <!-- 填写账号 -->
      <template v-if="active == 0">
        <div class="type-select">
          <el-radio-group v-model="radio" @change="account = ''">
            <el-radio label="1">邮箱验证</el-radio>
            <el-radio label="2">手机号验证</el-radio>
          </el-radio-group>
        </div>
        <div class="account">
          <p>请输入账号:</p>
          <el-input v-if="radio == 1" placeholder="请输入邮箱" v-model="account" />
          <el-input v-if="radio == 2" placeholder="请输入手机号" v-model="account" />
        </div>
        <div class="right">
          <base-button width="220px" height="40px" borderRadius="6px" @click="checkAccount">下一步</base-button>
        </div>
      </template>

      <!-- 输入验证码 -->
      <template v-else-if="active == 1">
        <div class="code">
          <p>请输入验证码:</p>
          <el-input v-model="code" />
        </div>
        <div class="flex-between">
          <base-button width="220px" height="40px" borderRadius="6px" @click="active--">上一步</base-button>
          <base-button width="220px" height="40px" borderRadius="6px" @click="checkCode">下一步</base-button>
        </div>
      </template>

      <!-- 设置新密码 -->
      <template v-else-if="active == 2">
        <div class="password">
          <p>请输入新密码:</p>
          <el-input v-model="password" type="password" />
          <p style="margin-top: 16px">请再次输入新密码:</p>
          <el-input v-model="repeatPassword" type="password" />
        </div>
        <div class="flex-between">
          <base-button width="220px" height="40px" borderRadius="6px" @click="active--">上一步</base-button>
          <base-button width="220px" height="40px" borderRadius="6px" @click="changePassword">下一步</base-button>
        </div>
      </template>

      <!-- 完成 -->
      <div v-else class="result">
        <i class="el-icon-check"></i>
        <h3>完成</h3>
        <p>密码修改成功，请重新登录({{ countTime }}s)</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiUser } from "@/api/user";
export default {
  name: "ResetPassword",
  props: {},
  data() {
    return {
      active: 0,
      radio: "1",
      account: "",
      code: "",
      password: "",
      repeatPassword: "",
      countTime: 5
    };
  },
  created() {},
  mounted() {},
  methods: {
    checkAccount() {
      if (this.radio === "2") {
        if (!/^1[3456789]\d{9}$/.test(this.account)) {
          this.$message.error("请输入正确的手机号");
          return;
        }
        ApiUser.getTelCode({ mobile: this.account, sendType: 2 }).then(() => {
          this.active = 1;
        });
      } else {
        let reg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;
        if (!reg.test(this.account)) {
          this.$message.error("请输入正确的邮箱号");
          return;
        }
        ApiUser.getEmailCode({ email: this.account }).then(() => {
          this.active = 1;
        });
      }
    },
    checkCode() {
      if (!this.code) {
        this.$message.error("请输入验证码");
        return;
      }
      let m = {
        account: this.account,
        code: this.code,
        type: this.radio
      };
      ApiUser.checkCode(m).then(() => {
        this.active = 2;
      });
    },
    changePassword() {
      if (!this.password) {
        this.$message.error("请输入新密码");
        return;
      }
      if (!this.repeatPassword) {
        this.$message.error("请再次输入新密码");
        return;
      }
      if (this.password !== this.repeatPassword) {
        this.$message.error("两次密码不一致");
        return;
      }
      let m = {
        code: this.code,
        type: this.radio,
        newPassword: this.password,
        email: this.radio == 1 ? this.account : "",
        mobile: this.radio == 2 ? this.account : ""
      };
      ApiUser.changePassword(m).then(() => {
        this.$message.success("修改密码成功");
        this.active = 3;
        this.startCountDown();
      });
    },
    startCountDown() {
      this.tmr = setInterval(() => {
        this.countTime--;
        if (this.countTime <= 0) {
          clearInterval(this.tmr);
          this.$router.back();
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  height: $header-height;
  span {
    display: inline-block;
  }
  span:first-child {
    font-weight: 500;
    width: 240px;
    height: $header-height;
    color: #fff;
    line-height: $header-height;
    text-align: center;
    background: $primary-linear-gradient;
  }

  span:last-child {
    padding: 0 24px;
    line-height: $header-height;
  }
}

.el-steps {
  box-sizing: border-box;
  padding: 21px 224px;
  height: 108px;
  background: rgba(246, 246, 246, 1);
  ::v-deep .el-step__icon {
    width: 32px;
    height: 32px;
  }

  ::v-deep .is-finish {
    font-weight: 500;
    font-size: 16px;
    color: $bold-font-color;
    .el-step__icon.is-text {
      color: #fff;
      border: none;
      background: $primary-linear-gradient;
    }
  }

  ::v-deep .is-process {
    color: #00c4b3;
    border-color: #00c4b3;
  }

  ::v-deep .is-wait {
    .el-step__line {
      height: 1px;
      background: rgba(0, 0, 0, 0.15);
    }
  }
}

.main-content {
  margin: 50px auto 0;
  width: 500px;
  // .type-select {
  //   margin-bottom: 40px;
  // }
  p {
    color: $bold-font-color;
    padding: 0 0 16px;
  }
  .account {
    padding: 40px 0;
  }
  .code,
  .password {
    padding-bottom: 40px;
  }

  .el-icon-check {
    margin-top: 5px;
    padding: 10px;
    color: #fff;
    font-size: 50px;
    font-weight: bold;
    border-radius: 50%;
    background: #03ada2;
  }

  .result {
    margin-top: 5px;
    text-align: center;
    h3 {
      padding: 29px 0 8px;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: $bold-font-color;
    }
    p {
      color: #999;
    }
  }
}
</style>
