<!--
 * @Description: 用户登陆页面
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/views/user/Login.vue
-->
<template>
  <div class="user-login">
    <div class="pos-center flex">
      <div class="bg">
        <img src="../../assets/user/logo-01.png" alt="朗绿logo" />
        <h1>智慧云平台 部署系统</h1>
      </div>
      <div class="login">
        <h2>登陆/Login</h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0">
          <el-form-item prop="account">
            <el-input v-model="ruleForm.account" placeholder="请输入用户名" @keyup.enter="toLogin"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入密码"
              type="password"
              @keyup.enter="toLogin"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="forgot-password">
          <span @click="forgotPassword">忘记密码？</span>
        </div>
        <div>
          <base-button height="46px" :active="active" :loading="loading" @click="toLogin">登 陆</base-button>
        </div>
      </div>
    </div>
    <div class="record">
      <el-link type="primary" href="https://beian.miit.gov.cn/">苏ICP备2021015203号-6</el-link>
      <span class="company-name">朗绿慧居建筑科技有限公司</span>
    </div>
  </div>
</template>

<script>
import { ApiUser } from "@/api/user";
import { encode } from "js-base64";
export default {
  name: "Login",
  props: {},
  data() {
    return {
      ruleForm: {
        account: "",
        password: ""
        // account: "",
        // password: ""
      },
      loading: false,
      rules: {
        account: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      }
    };
  },
  computed: {
    active() {
      return this.ruleForm.account && this.ruleForm.password;
    }
  },
  methods: {
    toLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let m = {
            account: this.ruleForm.account,
            password: encode(this.ruleForm.password)
          };
          this.loading = true;
          ApiUser.login(m)
            .then((res) => {
              this.$ls.set("token", res.token);
              this.$router.push("/");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    forgotPassword() {
      this.$router.push("/user/resetpassword");
    }
  }
};
</script>

<style lang="scss" scoped>
.user-login {
  position: relative;
  height: 100vh;
  background: url("../../assets/user/login-bg.png") no-repeat;
  background-size: cover;
  .record {
    position: fixed;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #fff;
    .company-name {
      margin-left: 10px;
    }
    .el-link.el-link--primary {
      color: #fff;
    }
  }
}
.flex {
  margin: 0 auto;
  width: 1000px;
  height: 590px;
  & > * {
    width: 50%;
  }
  .bg {
    padding-top: 250px;
    text-align: center;
    background: url("../../assets/user/sys-bg.png") no-repeat;
    h1 {
      padding-top: 24px;
      font-size: 24px;
      line-height: 34px;
      color: #fff;
    }
    img {
      width: 187px;
      height: 45px;
    }
  }
  & > .login {
    box-sizing: border-box;
    padding: 134px 56px;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    background: #fff;

    h2 {
      padding-bottom: 10px;
      font-size: 24px;
      line-height: 34px;
      color: $bold-font-color;
    }

    ::v-deep .el-form-item {
      margin-top: 25px;
      margin-bottom: 0;
    }
    ::v-deep .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .forgot-password {
    padding: 26px 0 50px;
    text-align: right;
    line-height: 20px;
    color: #00c4b3;
  }
}
</style>
